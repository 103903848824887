import React, { useState } from "react";
import EndPoints from "./EndPoints";
import moment from "moment";
export const emailid = "nanciya.c@praniontech.com";
export const userid = "301666";
export const loginFrom = "Desktop"
export const textAreaLimit = 999
const Constants = {
    casteOtherId: "433",
    indiaIsdCodeID: 499,
    christialId: "18",
    casteNotKnowId: "1125",
    verificationStatusKeys: {
        notVerified: "N",
        rejected: "R",
        accepted: "A",
        pending: "P"
    },
    profileStatusOptions: [
        'hidden',
        'inactive',
        'suspension',
        'delete',
    ],
    relationOptions: [
        {
            id: 5,
            name: "Mother",
            value: "Mother"
        }, {
            id: 6,
            name: "Father",
            value: "Father"
        }],
    maxConversation: 20,
    matchRowCount: 10,
    bannerPlace: 5,
    contentCardPlace: 10,
    bottomOffset: 60,
    matchCatogory: {
        initial: "INITIAL",
        nameSearch: "NAME_SEARCH",
        selectedMatch: "SELECTED_MATCH",
        idSearch: "ID_SEARCH",
        otherMatches: "OTHER_MATCH",
        filterMatches: "FILTER_MATCH",
        basicSearch: "BASIC_SEARCH",
    },
    helpLinePhNo: "044 4014 6969",
    kalyanSupportWhatsappUrl: (id) => `https://api.whatsapp.com/send?phone=9884340098&text=Hi!, this is My Profile ID is ${id} and this is My Photo`,
    kalyanWhatsappUrl: (number) => `https://api.whatsapp.com/send?phone=${number}`,
    helpLineMailId: " help@kalyanmatrimony.com",
    photoRejectedMessage: "Photo added by you is Rejected by our validation team for the following reason",
    waitForValidationMessage: "Wait till Validation",
    appDownloadWaitTime: 30000,
    kalyanRewardsPopupWaitTime: 120000,
    metaTitleNRI: "Matrimony for NRI | Best NRI Matrimonial Site | Kalyan Matrimony",
    metaDescriptionNRI: "Find your perfect NRI match with Kalyan Matrimony. The best NRI matrimonial site, offering mobile verified profiles and intelligent matchmaking services. Start your journey to a lifelong partnership today!",
    loginLocalStorageKeys: {
        chatLogin: "INTERNAL_LOGIN",
        chatLoginId: "INTERNAL_LOGIN_ID",
        isLoggedIn: "IS_LOGGED_IN",
        loginName: "LOGIN_NAME",
        loginPassword: "LOGIN_PASSWORD",
        loginId: "LOGIN_ID",
        loginEmail: "LOGIN_EMAIL",
        loginPhone: "LOGIN_PHONE",
        loginCountryCode: "LOGIN_COUNTRY_CODE",
        loginUserName: "LOGIN_USER_NAME",
        afterRegister: "AFTER_REGISTER",
        showLoader: "SHOW_LOADER",
        loginUserStatus: "LOGIN_USER_STATUS",
        profileId: "PROFILE_ID",
        isCommunitySite: "IS_COMMUNITY_SITE",
        tagContent: "TAG_CONTENT",
        isFirstLogin: "IS_FIRST_LOGIN",
        loginFrom: "LOGIN_FROM",
        loginDevice: "LOGIN_DEVICE",
        appDownloadPopupAlreadyShowed: "APP_DOWNLOAD_ALREADY_SHOWED",
        showDownloadPopup: "SHOW_DOWNLOAD_POPUP",
        currentProfileId: "CURRENT_PROFILE_ID",
        verificationLoginFrom: "VERIFICATION_LOGIN_FROM",
        afterRegisterFlow: "AFTER_REGISTER_FLOW",
        showMigrationPopup: "SHOW_MIGRATION_POPUP",
        allowAfterRegPage: "ALLOW_AFTER_REG_PAGE",
        loginCurrentPage: "LOGIN_CURRENT_PAGE",
        notificationId: "NOTIFICATION_ID",
        notificationPath: "NOTIFICATION_PATH",
        loginUrl: "LOGIN_URL",
        filterState: "filterState",
        familyLoginId: 'familyLoginId',
        familyLoginMobileNumber: 'familyLoginMobileNumber',
        familyLoginRelation: 'familyLoginRelation',
        fcmToken: "fcmToken",
        loginFromAdmin: "loginFromAdmin",
        parentsMobileNumber: "parentsMobileNumber",
        isChecked: "isChecked",
        isFrom: "isFrom",
        isCheckbox: "isCheckbox",
        From: "From",
        IS_PROMPT: "IS_PROMPT",
        messageId: "messageId",
        isLoginFromSms: "isLoginFromSms",
        loginFromUrl: "loginFromUrl",
        customerChatClicked: "customerChatClicked",
        isCustomer: "isCustomer",
        admUserId: "admUserId",
        conversationId: "conversationId",
        isRated: "isRated",
        RankingClick: "RankingClick",
        isNRIPage: "isNRIPage"
        // customerServiceLoginId: 'customerServiceLoginId',
        // customerConversationId: 'customerConversation',
        // customerReceiverId: 'customerReceiver'
    },
    kalyanJewellerUtm: "?utm_source=KalyanMatrimony&utm_medium=website&utm_campaign=jewellery",

    kalyanSupportWhatsappLink: (phone, reinvitecontent) => `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(reinvitecontent)}.`,

    kalyanSupportMessageLink: (phone, reinvitecontent) => `sms:${phone}?body=${encodeURIComponent(reinvitecontent)}`,

    kalyanSupportFb: (content) => `https://m.me/?text=${content}`,

    kalyanSupportMail: (content) => `mailto:?subject=Join Kalyan Matrimony&body=${content}`,


}

export default Constants;

export const blockInvalidCharForNumber = (e, count, value) => {
    //console.log(e.keyCode, e.target.value,count, value?.length,"kjmnkj");
    if (['e', 'E', '+', '-'].includes(e.key)) {
        e.preventDefault()
    }
    else {
        if (e.keyCode == 8 || e.keyCode == 46) {
        }
        else {
            if (count == e.target.value?.length) {
                e.preventDefault()
            } else {

            }

        }
    }
}

var lastnumber;
export const blockInvalidCharForTextInteger = (e, count, value) => {
    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode == 8) || (e.keyCode == 46)) {
        if (lastnumber == 16) {
            e.preventDefault()
        }
        if (count == e.target.value?.length) {
            e.preventDefault()
        }
    }
    else {
        e.preventDefault()
    }
    lastkeyName = e.keyCode
}

var lastkeyName;
export const blockInvalidCharForText = (e) => {
    if (e.keyCode == 18) {
        e.preventDefault();
    }
    else if (e.keyCode == 190) {
        if (lastkeyName == 16) {
            e.preventDefault();
        }
        if ((e.target.value.indexOf(".") > -1) || (e.target.value.indexOf(">") > -1)) {
            e.preventDefault();
        }
    }
    else {
        if ((e.keyCode == 32) || (e.keyCode == 16) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 95 && e.keyCode <= 122) || (e.keyCode == 190) || (e.keyCode == 8) || (e.keyCode == 46) || (e.keyCode == 37 && e.keyCode <= 40)) {
            if ((e.keyCode == 32 && lastkeyName == 32)) {
                e.preventDefault();
            }
            else {
                lastkeyName = e.keyCode

            }
        }
        else {
            e.preventDefault();
        }
    }
}

var lastkey;
export const ignoreSpaces = e => {
    if (e.keyCode == 32 && lastkey == 32 && e.key == " ") {
        e.preventDefault();
    }
    else {
        lastkey = e.keyCode
    }
}

export function MenuFunction() {
    const [menuAction, setMenuAction] = useState({
        matches: true,
        search: false,
        inbox: false,
        editProfile: false,
        notification: false,
        paynow: false,
    });
    return { menuAction, setMenuAction };
}

export const InboxFilters = {
    message: [
        { lable: "All Messages", value: "All Message", isSelected: false, urlPath: "all-messages" },
        { lable: "Unread Messages", value: "Unread Message", isSelected: false, urlPath: "unread-messages" },
        { lable: "Reply Pending", value: "Replypending Message", isSelected: false, urlPath: "reply-pending" },
        { lable: "Replied Messages", value: "Read Message", isSelected: false, urlPath: "replied-messages" },
        { lable: "Sent Messages", value: "Sent Message", isSelected: false, urlPath: "sent-messages" },
    ],
    requestReceived: [
        { lable: "All Requests", value: "All", isSelected: false, urlPath: "all-requests-received" },
        { lable: "Photo Requests", value: "Photo", isSelected: false, urlPath: "photo-requests-received" },
        { lable: "Horoscope Requests", value: "Horoscope", isSelected: false, urlPath: "horoscope-requests-received" },
        { lable: "Trust Documents", value: "Trust", isSelected: false, urlPath: "trust-documents-received" },
        { lable: "Parents Number Requests", value: "Parents Number", isSelected: false, urlPath: "parents-number-requests-received" },
    ],
    sentRequest: [
        { lable: "All Requests", value: "All", isSelected: false, urlPath: "all-requests-sent" },
        { lable: "Photo Requests", value: "Photo", isSelected: false, urlPath: "photo-requests-sent" },
        { lable: "Horoscope Requests", value: "Horoscope", isSelected: false, urlPath: "horoscope-requests-sent" },
        { lable: "Trust Documents", value: "Trust", isSelected: false, urlPath: "trust-documents-sent" },
        { lable: "Parents Number Requests", value: "Parents Number", isSelected: false, urlPath: "parents-number-requests-sent" },
    ],
};

export const EditProfileMenu = [
    { id: "1", lable: "My Profile", urlPath: "my-profile" },
    { id: "2", lable: "Partner Preferences", urlPath: "partner-preferences" },
    { id: "3", lable: "Photos", urlPath: "photos" },
    { id: "4", lable: "Trust Mark", urlPath: "trust-mark" },
    { id: "5", lable: "Horoscope", urlPath: "horoscope" },
    { id: "6", lable: "Settings", urlPath: "settings" },
    { id: "7", lable: "My Plan", urlPath: "my-plan" },
    { id: "8", lable: "Profile Viewed By Me", urlPath: "profile-viewed-by-me" },
    { id: "9", lable: "Who Viewed My Profile", urlPath: "who-viewed-my-profile" },
    { id: "10", lable: "Phone Numbers Viewed By Me", urlPath: "phone-numbers-viewed-by-me" },
    { id: "11", lable: "Who Viewed My Phone Number", urlPath: "who-viewed-my-phone-number" },

    // lite paths
    { id: "2", lable: "Partner Preferences", urlPath: "partner-expectations" },
    { id: "12", lable: "Hobbies & Interests", urlPath: "hobbies-interests" },
    { id: "13", lable: "Kalyan Rewards", urlPath: "kalyan-rewards" },
]

export const nameNumericList = [
    "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten"
]

export const mailExtensionList = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "aol.com",
    "hotmail.co.uk",
    "hotmail.fr",
    "msn.com",
    "yahoo.fr",
    "wanadoo.fr",
    "orange.fr",
    "comcast.net",
    "yahoo.co.uk",
    "yahoo.com.br",
    "yahoo.co.in",
    "live.com",
    "rediffmail.com",
    "free.fr",
    "gmx.de",
    "web.de",
    "yandex.ru",
    "ymail.com",
    "libero.it",
    "outlook.com",
    "uol.com.br",
    "bol.com.br",
    "mail.ru",
    "cox.net",
    "hotmail.it",
    "sbcglobal.net",
    "sfr.fr",
    "live.fr",
    "verizon.net",
    "live.co.uk",
    "googlemail.com",
    "yahoo.es",
    "ig.com.br",
    "live.nl",
    "bigpond.com",
    "terra.com.br",
    "yahoo.it",
    "neuf.fr",
    "yahoo.de",
    "alice.it",
    "rocketmail.com",
    "att.net",
    "laposte.net",
    "facebook.com",
    "bellsouth.net",
    "yahoo.in",
    "hotmail.es",
    "charter.net",
    "yahoo.ca",
    "yahoo.com.au",
    "rambler.ru",
    "hotmail.de",
    "tiscali.it",
    "shaw.ca",
    "yahoo.co.jp",
    "sky.com",
    "earthlink.net",
    "optonline.net",
    "freenet.de",
    "t-online.de",
    "aliceadsl.fr",
    "virgilio.it",
    "home.nl",
    "qq.com",
    "telenet.be",
    "me.com",
    "yahoo.com.ar",
    "tiscali.co.uk",
    "yahoo.com.mx",
    "voila.fr",
    "gmx.net",
    "mail.com",
    "planet.nl",
    "tin.it",
    "live.it",
    "ntlworld.com",
    "arcor.de",
    "yahoo.co.id",
    "frontiernet.net",
    "hetnet.nl",
    "live.com.au",
    "yahoo.com.sg",
    "zonnet.nl",
    "club-internet.fr",
    "juno.com",
    "optusnet.com.au",
    "blueyonder.co.uk",
    "bluewin.ch",
    "skynet.be",
    "sympatico.ca",
    "windstream.net",
    "mac.com",
    "centurytel.net",
    "chello.nl",
    "live.ca",
    "aim.com",
    "bigpond.net.au"
]

export const ChatFilesUrl = 'https://cms-uat.kalyanmatrimony.com/storage/'

export const formatMessageTime = (dateTime) => {
    try {
        return moment.utc(dateTime).format("hh:mm A")
    } catch (error) {
        return ""
    }
}

export const formatMessageGroupDate = (dateTime) => {
    try {
        return moment.utc(dateTime).format("DD/MM/YYYY")
    } catch (error) {
        return ""
    }
}

export const formattedTime = (utcTimeString) => {
    const date = new Date(utcTimeString);
    const formatted = new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    }).format(date);
    return formatted;

}

const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);


export const FilterOptions = [
    {
        id: 1,
        label: isCommunitySite ? "New Matches" : 'Weekly Matches',
        key: 'WeeklyMatches',
        responseKey: "Weeklymatches",
        url: EndPoints.weeklyMatchesURl(),
        getRequest: ({ page, pageSize, email, userId }) => ({
            "email": email,
            "userId": userId,
            "weeklypageno": page,
            "weeklypagesize": pageSize,
            "status": "new",
            "ignored": "",
            "shortlisted": "",
            "viewed": ""
        })
    },
    {
        id: 2,
        label: 'Recommended Matches',
        key: 'RecommendedMatches',
        responseKey: "recommendationsmatches",
        url: EndPoints.recommendedMatchesUrl(),
        getRequest: ({ page, pageSize, email, userId }) => ({
            "email": email,
            "userId": userId,
            "recomendationpageno": page,
            "recomendationpagesize": pageSize,
            "status": "new",
            "ignored": "",
            "shortlisted": "",
            "viewed": ""
        })
    },
    {
        id: 3,
        label: 'Profiles matching my Expectations',
        key: 'ProfilesMatching',
        responseKey: "Profileslookingfor",
        url: EndPoints.profilesLookingForUrl(),
        countUrl: EndPoints.getFilterCount(),
        countRequest: {
            "section": "profileMatchingMyExpectation"
        },
        getRequest: ({ page, pageSize, email, userId }) => ({
            "email": email,
            "userId": userId,
            "profileslookingforpageno": page,
            "profileslookingforpagesize": pageSize,
            "status": "new",
            "ignored": "",
            "shortlisted": "",
            "viewed": ""
        })
    },
    {
        id: 4,
        label: 'Profiles Looking for Me',
        key: 'ProfilesLookingFor',
        responseKey: "Profileslookingforme",
        url: EndPoints.profilesLookingFormeUrl(),
        getRequest: ({ page, pageSize, email, userId }) => ({
            "email": email,
            "userId": userId,
            "profileslookingformepageno": page,
            "profileslookingformepagesize": pageSize,
            "status": "new",
            "ignored": "",
            "shortlisted": "",
            "viewed": ""
        })
    },
    {
        id: 5,
        label: 'Mutual Matches',
        key: 'MutualMatches',
        responseKey: "Mutalmatches",
        url: EndPoints.mutalMatchesUrl(),
        getRequest: ({ page, pageSize, email, userId }) => ({
            "email": email,
            "userId": userId,
            "mutalmatchespageno": page,
            "mutalmatchespagesize": pageSize,
            "status": "new",
            "ignored": "",
            "shortlisted": "",
            "viewed": ""
        })
    },
    {
        id: 6,
        label: 'Profiles Selected by Me',
        key: 'ProfilesSelectedBy',
        url: EndPoints.selectedProfilesUrl(),
        responseKey: "Selectedprofiles",
        getRequest: ({ page, pageSize, email, userId }) => ({
            "email": email,
            "userId": userId,
            "selectedprofilespageno": page,
            "limit": pageSize,
            "status": "new"
        })
    },
    {
        id: 7,
        label: 'Profiles Viewed by Me',
        key: 'ProfilesViewed',
        responseKey: "profileList",
        url: EndPoints.getviewedphoneprofileanddocumentv3(),
        countUrl: EndPoints.getCountViaCube(),
        countRequest: {
            "parameter": "mat_profileviews",
            type: "viewer_id",
            parameterKey: "count",
        },
        getRequest: ({ page, pageSize, email, userId }) => ({
            "email": email,
            "userId": userId,
            "loginFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
            "pageno": page,
            "size": pageSize,
            "type": "profilesviewedbyme"
        })
    },
    {
        id: 8,
        label: 'Profiles who Viewed my Profile',
        key: 'ProfilesWhoViewed',
        responseKey: "profileList",
        url: EndPoints.getviewedphoneprofileanddocumentv3(),
        countUrl: EndPoints.getCountViaCube(),
        countRequest: {
            "parameter": "mat_profileviews",
            type: "viewee_id",
            parameterKey: "count_",
        },
        getRequest: ({ page, pageSize, email, userId }) => ({
            "email": email,
            "userId": userId,
            "loginFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
            "pageno": page,
            "size": pageSize,
            "type": "profilesviewedbyothers"
        })
    },
    {
        id: 9,
        label: 'Phone Numbers Viewed by Me',
        key: 'PhoneNumbersViewed',
        responseKey: "profileList",
        url: EndPoints.getviewedphoneprofileanddocumentv3(),
        countUrl: EndPoints.getCountViaCube(),
        countRequest: {
            "parameter": "mat_phoneviews",
            type: "viewer_id",
            parameterKey: "count",
        },
        getRequest: ({ page, pageSize, email, userId }) => ({
            "email": email,
            "userId": userId,
            "loginFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
            "pageno": page,
            "size": pageSize,
            "type": "phonesviewedbyme"
        })
    },
    {
        id: 10,
        label: 'Who Viewed my Phone Number',
        key: 'WhoViewedMyPhoneNumber',
        responseKey: "profileList",
        url: EndPoints.getviewedphoneprofileanddocumentv3(),
        countUrl: EndPoints.getCountViaCube(),
        countRequest: {
            "parameter": "mat_phoneviews",
            type: "viewee_id",
            parameterKey: "count_",
        },
        getRequest: ({ page, pageSize, email, userId }) => ({
            "email": email,
            "userId": userId,
            "loginFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
            "pageno": page,
            "size": pageSize,
            "type": "phonesviewedbyothers"
        })
    },
    {
        id: 11,
        label: 'Documents viewed by me',
        key: 'DocumentsViewedBy',
        responseKey: "profileList",
        url: EndPoints.getviewedphoneprofileanddocumentv3(),
        countUrl: EndPoints.getCountViaCube(),
        countRequest: {
            "parameter": "mat_documentviews",
            type: "viewer_id",
            parameterKey: "count",
        },
        getRequest: ({ page, pageSize, email, userId }) => ({
            "email": email,
            "userId": userId,
            "loginFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
            "pageno": page,
            "size": pageSize,
            "type": "documentviewedbyme"
        })
    },
    {
        id: 12,
        label: 'Who Viewed my Documents',
        key: 'WhoViewedMyDocuments',
        responseKey: "profileList",
        url: EndPoints.getviewedphoneprofileanddocumentv3(),
        countUrl: EndPoints.getCountViaCube(),
        countRequest: {
            "parameter": "mat_documentviews",
            type: "viewee_id",
            parameterKey: "count_",
        },
        getRequest: ({ page, pageSize, email, userId }) => ({
            "email": email,
            "userId": userId,
            "loginFrom": localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
            "pageno": page,
            "size": pageSize,
            "type": "documentviewedbyothers"
        })
    },
    {
        id: 13,
        label: 'Photo Request Sent by Me',
        key: 'PhotoRequest',
        responseKey: "inboxdetails",
        url: EndPoints.requestviewliteapp(),
        getRequest: ({ page, pageSize, email, userId }) => ({
            "email": email,
            "userId": userId,
            "requesttype": "Photo",
            "type": "Sent",
            "pageno": page,
            "size": pageSize
        })
    },
    {
        id: 14,
        label: 'Received Photo Request',
        key: 'ReceivedPhoto',
        responseKey: "inboxdetails",
        url: EndPoints.requestviewliteapp(),
        getRequest: ({ page, pageSize, email, userId }) => ({
            "email": email,
            "userId": userId,
            "requesttype": "Photo",
            "type": "Received",
            "pageno": page,
            "size": pageSize
        })
    },
    {
        id: 15,
        label: 'Response Received Profiles',
        key: 'ResponseReceived',
        url: EndPoints.responseReceivedUrl(),
        responseKey: "response_received",
        getRequest: ({ page, pageSize, email, userId }) => ({
            "email": email,
            "userId": userId,
            "response_receivedpageno": page,
            "response_receivedpagesize": pageSize,
            "status": "new"
        })
    },
    {
        id: 16,
        label: 'Documents Request Sent by Me',
        key: 'DocumentsRequest',
        url: EndPoints.requestviewliteapp(),
        responseKey: "inboxdetails",
        getRequest: ({ page, pageSize, email, userId }) => ({
            "email": email,
            "userId": userId,
            "requesttype": "All",
            "type": "Sent",
            "pageno": page,
            "size": pageSize
        })
    },
    {
        id: 17,
        label: 'Received Document Requests',
        key: 'ReceivedDocument',
        responseKey: "inboxdetails",
        url: EndPoints.requestviewliteapp(),
        getRequest: ({ page, pageSize, email, userId }) => ({
            "email": email,
            "userId": userId,
            "requesttype": "All",
            "type": "Received",
            "pageno": page,
            "size": pageSize
        })
    },
    {
        id: 18,
        label: 'Profiles Rejected by Me',
        key: 'ProfilesRejected',
        responseKey: "rejectedProfiles",
        url: EndPoints.rejectedprofilesloadmore(),
        getRequest: ({ page, pageSize, email, userId }) => ({
            "email": email,
            "userId": userId,
            "pageno": page,
            "pagesize": pageSize,
            "status": "new"
        })
    }
]

export const FilterItems = [
    { id: 1, checkBox: false, checked: false, count: 0, label: "Age", tab: 1 },
    { id: 2, checkBox: false, checked: false, count: 0, label: "Height", tab: 1 },
    { id: 3, checkBox: false, checked: false, count: 0, label: "Marital Status", tab: 1, fname: "maritalStatus" },
    { id: 4, checkBox: false, checked: false, count: 3, label: "Mother Tongue", tab: 1, fname: "motherTongue" },
    { id: 5, checkBox: false, checked: false, count: 3, label: "Religion", tab: 1, fname: "religion" },
    {
        id: 6,
        checkBox: false,
        checked: false,
        count: 3,
        label: "Denomination",
        tab: 1,
        fname: "domain"
    },
    { id: 7, checkBox: false, checked: false, count: 0, label: "Caste", tab: 1, fname: "caste" },
    { id: 8, checkBox: false, checked: false, count: 3, label: "Sub-Caste", tab: 1, fname: "subCaste" },
    { id: 9, checkBox: false, checked: false, count: 3, label: "Education", tab: 1, fname: "education" },
    {
        id: 21,
        checkBox: false,
        checked: false,
        count: 3,
        label: "Employment Type",
        tab: 2,
        fname: "employedIn"
    },
    {
        id: 22,
        checkBox: false,
        checked: false,
        count: 3,
        label: "Occupation",
        tab: 2,
        fname: "occupation"
    },
    {
        id: 23,
        checkBox: false,
        checked: false,
        count: 3,
        label: "Monthly Income",
        tab: 2,
        fname: "monthlyIncome"
    },
    {
        id: 24,
        checkBox: false,
        checked: false,
        count: 3,
        label: "Physical Status",
        tab: 2,
        fname: "physicalStatus"
    },
    { id: 10, checkBox: false, checked: false, count: 3, label: "Country Living in", tab: 1, fname: "country" },
    {
        id: 13,
        checkBox: false,
        checked: false,
        count: 3,
        label: "State",
        tab: 2,
        fname: "state"
    },
    {
        id: 14,
        checkBox: false,
        checked: false,
        count: 3,
        label: "City",
        tab: 2,
        fname: "city"
    },
    {
        id: 15,
        checkBox: false,
        checked: false,
        count: 3,
        label: "Star",
        tab: 2,
        fname: "star"
    },
    {
        id: 16,
        checkBox: false,
        checked: false,
        count: 3,
        label: "Raasi",
        tab: 2,
        fname: "raasi"
    },
    {
        id: 17,
        checkBox: false,
        checked: false,
        count: 3,
        label: "Chevvai Dosham/Manglik",
        tab: 2,
        fname: "dosham"
    },
    {
        id: 18,
        checkBox: false,
        checked: false,
        count: 3,
        label: "Food Habits",
        tab: 2,
        fname: "food"
    },
    {
        id: 19,
        checkBox: false,
        checked: false,
        count: 3,
        label: "Smoking",
        tab: 2,
        fname: "smoking"
    },
    {
        id: 20,
        checkBox: false,
        checked: false,
        count: 3,
        label: "Drinking",
        tab: 2,
        fname: "drinking"
    },

    { id: 11, checkBox: false, checked: false, count: 0, label: "Show Profile", tab: 1, fname: "showProfile" },
    { id: 12, checkBox: false, checked: false, count: 3, label: "Don't Show Profile", tab: 1, fname: "dontshowProfile" },

];
export const ShowProfileData = [
    { id: 1, name: "With Photo", selected: 'N', key: 'withPhoto' },
    { id: 2, name: "With Horoscope", selected: 'N', key: 'withHoroscope' }
];
export const DontShowProfileData = [
    { id: 1, name: "Rejected", selected: 'Y', key: 'ignored' },
    { id: 2, name: "Already Contacted", selected: 'Y', key: 'contacted' },
    { id: 3, name: "Viewed Profiles", selected: 'N', key: 'viewed' },
    { id: 4, name: "Selected Profiles", selected: 'N', key: 'shortlisted' }
];
export const EventKeys = {
    Matches_Search_Profile_Card_Id: 'Matches_Search_Profile_Card_Id',
    Matches_New_Conversation_Profile_Card_Id: 'Matches_New_Conversation_Profile_Card_Id',
    View_Profile_Photo_View_Photo_Button_Id: 'View_Profile_Photo_View_Photo_Button_Id',
    View_Profile_Photo_Select_Photo_Src: 'View_Profile_Photo_Select_Photo_Src',
    View_Profile_Profile_Card_Select_Button_Id: 'View_Profile_Profile_Card_Select_Button_Id',
    View_Profile_Profile_Card_Reject_Button_Id: 'View_Profile_Profile_Card_Reject_Button_Id',
    viewprofileid: 'viewprofileid',
    messageType: 'messageType',
    conversationId: 'conversationId',
    reciverId: 'reciverId',
    senderId: 'senderId',
    Appointment_Popup_Sumbit_Request: 'Appointment_Popup_Sumbit_Request',
    userEmail: 'userEmail',
    muteDuration: 'muteDuration',
    // Filter
    filterApplyRequest: "filterApplyRequest",
    filterBasicApplyRequest: "filterBasicApplyRequest",
    filterAdvanceApplyRequest: "filterAdvanceApplyRequest",
    // My Profile
    profileDescription: "profileDescription",
    profileBasic: "profileBasic",
    profileReligious: "profileReligious",
    profileHoroscope: "profileHoroscope",
    profileProfessional: "profileProfessional",
    profileLocation: "profileLocation",
    profileFamily: "profileFamily",
    profileHabits: "profileHabits",
    profilePrefernce: "profilePrefernce",
    profilePartnerPrefernceSave: "profilePartnerPrefernceSave",
    profilePartnerPreferncesSaveSearch: "profilePartnerPreferncesSaveSearch",
    profileDeleteMarriageFixedSumbit: "profileDeleteMarriageFixedSumbit",
    profileDeleteMarriedSumbit: "profileDeleteMarriedSumbit",
    profileDeletePreferToSearchLaterSumbit: "profileDeletePreferToSearchLaterSumbit",
    profileDeleteNotHappyWithWebsiteSumbit: "profileDeleteNotHappyWithWebsiteSumbit",
    profileDeleteOtherReasonsSumbit: "profileDeleteOtherReasonsSumbit",
    profileVerifyMobile: "profileVerifyMobile",

    // profileRegister keys
    profileRegisterDatas: 'profileRegisterDatas',
    //After Registeration
    setPartnerExpectationDatas: 'setPartnerExpectationDatas',
    profileGenerateHoroscope: "profileGenerateHoroscope",
    profileSettingsSave: "profileSettingsSave",
    profilePhoneNumberSave: "profilePhoneNumberSave",
    profileStatusSave: "profileStatusSave",
    profileEmailIdSave: "profileEmailIdSave",
    profilePaswordSave: "profilePaswordSave",
    profilePrivacySettingsSave: "profilePrivacySettingsSave",

    Family_Parent_Number_Popup_Submit_Button_Request: "Family_Parent_Number_Popup_Submit_Button_Request",
    Family_Add_More_Send_Invite_Button_Request: "Family_Add_More_Send_Invite_Button_Request",
    Home_page_Register_Submit_Button_Request: "Home_page_Register_Submit_Button_Request",
    HomePageLoginUserDetails: 'HomePageLoginUserDetails',
    Home_page_Login_as_FamilyFriend_Submit_Button_Request: "Home_page_Login_as_FamilyFriend_Submit_Button_Request",
    Home_page_Forgot_Password_Submit_Button_Request: "Home_page_Forgot_Password_Submit_Button_Request",
    phoneNumber: "phoneNumber",
    Home_page_Signin_Submit_Button_Request: "Home_page_Signin_Submit_Button_Request",
    FamilyFriendLoginMemberId: 'FamilyFriendLoginMemberId',
    UploadingHoroscopeFile: 'UploadingHoroscopeFile',

    // request Field form submit
    requestDescription: "requestDescription",
    requestReligiousDetails: "requestReligiousDetails",
    requestProfessionalDetails: "requestProfessionalDetails",
    requestAstroDetails: "requestAstroDetails",
    requestFamilyDetails: "requestFamilyDetails",
    requestHobbiesDetails: "requestHobbiesDetails",
    requestAdditionalDetails: "requestAdditionalDetails",
    clcsavebutton: "clcsavebutton",
    // clc horoscope
    generateHoroscopeDetails: 'generateHoroscopeDetails',
    uploadingHoroscopeDetails: 'uploadingHoroscopeDetails',
}

export const EventName = {
    Matches_Matches_Matches_Button: 'Matches_Matches_Matches_Button',
    profileCard: "Matches_Matches_Profile Card_Button",
    Matches_Matches_Search_Field: "Matches_Matches_Search_Field",
    Matches_Matches_New_Conversation_Button: 'Matches_Matches_New Conversation_Button',
    Matches_Search_Selected_Profiles_Button: 'Matches_Search_Selected Profiles_Button',
    Matches_Search_Profile_Card_Button: 'Matches_Search_Profile Card_Button',
    Matches_New_Conversation_Already_Contacted_Button: 'Matches_New Conversation_Already Contacted_Button',
    Matches_New_Conversation_Selected_Profiles_Button: 'Matches_New Conversation_Selected Profiles_Button',
    Matches_New_Conversation_Profile_Card_Button: 'Matches_New Conversation_Profile Card_Button',
    Matches_Matches_Customer_Care_Button: 'Matches_Matches_Customer Care_Button',
    View_Profile_Photo_Request_Photo_Button: 'View Profile_Photo_Request Photo_Button',
    View_Profile_Photo_View_Photo_Button: 'View Profile_Photo_View Photo_Button',
    View_Profile_Photo_Left_Arrow_Button: 'View Profile_Photo_Left Arrow_Button',
    View_Profile_Photo_Right_Arrow_Button: 'View Profile_Photo_Right Arrow_Button',
    View_Profile_Photo_Select_Photo_Button: 'View Profile_Photo_Select Photo_Button',
    View_Profile_Match_Score_Match_Score_Button: 'View Profile_Match Score_Match Score_Button',
    View_Profile_Profile_Card_Select_Button: "View Profile_Profile Card_Select_Button",
    View_Profile_Profile_Card_Reject_Button: "View Profile_Profile Card_Reject_Button",
    View_Profile_Profile_Card_Selected_Button: 'View Profile_Profile Card_Selected_Button',
    View_Profile_Profile_Card_Rejected_Button: 'View Profile_Profile Card_Rejected_Button',
    View_Profile_Contact_Options_Phone_Number_Button: 'View Profile_Contact Options_Phone Number_Button',
    View_Profile_Contact_Options_Request_Parents_Number_Button: "View Profile_Contact Options_Request Parent's Number_Button",
    View_Profile_Contact_Options_Parents_Number_Button: "View Profile_Contact Options_Parent's Number_Button",
    View_Profile_Contact_Options_Request_Horoscope_Button: 'View Profile_Contact Options_Request Horoscope_Button',
    View_Profile_Contact_Options_View_Horoscope_Button: 'View Profile_Contact Options_View Horoscope_Button',
    View_Profile_Contact_Options_Request_Trust_Document_Button: 'View Profile_Contact Options_Request Trust Document_Button',
    View_Profile_Contact_Options_View_Trust_Document_Button: 'View Profile_Contact Options_View Trust Document_Button',
    View_Profile_Contact_Options_Start_Conversation_Button: 'View Profile_Contact Options_Start Conversation_Button',
    View_Profile_Contact_Options_Send_Message_Button: 'View Profile_Contact Options_Send Message_Button',
    View_Profile_Contact_Options_Video_Call_Button: 'View Profile_Contact Options_Video Call_Button',
    View_Profile_All_Unpaid_Popup_Pay_Now_Button: 'View Profile_All Unpaid Popup_Pay Now_Button',
    View_Profile_Banner_Banner_Button: 'View Profile Banner_Banner_Button',
    View_Profile_Banner_Banner_Button_Skip: 'View_Profile Banner_Banner_Button_Skip',
    View_Profile_Banner_Banner_Button_Sumbit: 'View_Profile Banner_Banner_Button_Sumbit',
    View_Profile_Next_Button_Next_Button_Button: 'View Profile_Next Button_Next Button_Button',
    View_Profile_Previous_Button_Previous_Button_Button: 'View Profile_Previous Button_Previous Button_Button',
    View_Profile_Partner_Preferences_Edit_Now_Button: 'View Profile_Partner Preferences_Edit Now_Button',
    View_Profile_Report_Report_an_abuse_Button: 'View Profile_Report_Report an abuse_Button',
    profileActionPhoto: "PROFILE_ACTION_PHOTO",
    chatSendText: "CHAT_SEND_TEXT",
    chatSendAttachMent: "CHAT_SEND_ATTACHMENT",
    chatCameraIcon: "CHAT_CAMERA_ICON",
    Your_Expectation_and_Opposite_Profile_Button: "Your Expectation and Opposite Profile_Button",
    Opposite_Expectations_and_My_Profile_Button: "Opposite Expectations and My Profile_Button",
    V3_View_Profile_Contact_Options_Trust_Document_Added_Button: "V3_View Profile_Contact Options_Trust Document Added_Button",
    // Filter
    filterButon: 'Filter_Main Filter_Main Filter_Button',
    filterApplyButton: 'Filter_Main Filter_Apply_Button',
    filterClearallButton: 'Filter_Main Filter_Clear All_Button',
    filterCancelButton: 'Filter_Main Filter_Cancel_Button',
    filterEditFilterButton: 'Filter_Main Filter Results Page_Edit Filters_Button',
    filterEditFilterHereButton: 'Filter_Main Filter Results Page_Edit Filters here_Button',
    filterEditPartnerExpectationButton: 'Filter_Main Filter Results Page_Edit Partner Expectations_Button',
    filterYesButon: 'Filter_Main Filter ResultNo Results Found_Yes Button_Button',
    filterExploreDifferentProfileButton: 'Filter_Main Filter_Explore Different Profiles_Button',
    filterBasicSearchButton: 'Filter_Basic Search_Basic Search_Button',
    filterBasicApplyButton: 'Filter_Basic Search_Apply_Button',
    filterBasicClearallButton: 'Filter_Basic Search_Clear All_Button',
    filterBasicCancelButton: 'Filter_Basic Search_Cancel_Button',
    filterBasicEditFilterButton: 'Filter_Basic Search Results Page_Edit Filters_Button',
    filterBasiceditFilterhereButton: 'Filter_Basic Search Results Page_Edit Filters here_Button',
    filterBasiceditPartnerExpectationButton: 'Filter_Basic Search Results Page_Edit Partner Expectations_Button',
    filterBasicYesButton: 'Filter_Basic Search Results Page_Yes Button_Button',
    filterBasicSetAsPartnerExpectationYesButton: 'Filter_Basic Search Results Page_Set As Partner Expectation  Yes_Button',
    filterBasicSetAsPartnerExpectationNoButton: 'Filter_Basic Search Results Page_Set As Partner Expectation  No_Button',
    filterAdvanceSearchButton: 'Filter_Advnaced Search_Advanced Search_Field',
    filterAdvanceApplyButton: 'Filter_Advnaced Search_Apply_Button',
    filterAdvanceClearallButton: 'Filter_Advnaced Search_Clear All_Button',
    filterAdvanceCancelButton: 'Filter_Advanced Search_Cancel_Button',

    // 
    Home_Page_New_Register_Popup_Register_Now_Button : "Site_Home Page_New Register Popup_Register Now_Button",
    Home_Page_New_Register_Popup_Close_Button : "Site_Home Page_New Register Popup_Close_Button",

    // My Profile
    profileMainButton: 'My Profile_My Profile_My Profile_Button',
    profileAddHoroscope: 'My Profile_Horoscope_Add Horoscope_Button',
    profileViewHoroscope: 'My Profile_Horoscope_View Horoscope_Button',
    profileAddTrustdocument: 'My Profile_Trust Document_Add Trust Documents_Button',
    profileCompletionScoreButton: 'My Profile_Score_Profile Completion Score_Button',
    profileViewTrustdocument: 'My Profile_Trust Document_View Trust Document_Button',
    profilePhotoPlusButton: 'My Profile_Photo_Photo Plus icon_Button',
    profileDescriptionButton: 'My Profile_Description_Description_Button',
    profileDescriptionSaveButton: 'My Profile_Description_Save_Button',
    profileDescriptionCancelButton: 'My Profile_Description_Cancel_Button',
    profileBasicButton: 'My Profile_Basic_Basic_Button',
    profileBasicSaveButton: 'My Profile_Basic_Save_Button',
    profileBasicCancelButton: 'My Profile_Basic_Cancel_Button',
    profileReligiousButton: 'My Profile_Religious_Religious_Button',
    profileReligiousSaveButton: 'My Profile_Religious_Save_Button',
    profileReligiousCancelButton: 'My Profile_Religious_Cancel_Button',
    profileHoroscopeButton: 'My Profile_Horoscope_Horoscope_Button',
    profileHoroscopeSaveButton: 'My Profile_Horoscope_Save_Button',
    profileHoroscopeCancelButton: 'My Profile_Horoscope_Cancel_Button',
    profileProfessionalButton: 'My Profile_Professional_Professsional_Button',
    profileProfessionalSaveButton: 'My Profile_Professional_Save_Button',
    profileProfessionalCancelButton: 'My Profile_Professional_Cancel_Button',
    profileLocationButton: 'My Profile_Location_Location_Button',
    profileLocationSaveButton: 'My Profile_Location_Save_Button',
    profileLocationCancelButton: 'My Profile_Location_Cancel_Button',
    profileFamilyButton: 'My Profile_Family_Family_Button',
    profileFamilySaveButton: 'My Profile_Family_Save_Button',
    profileFamilyCancelButton: 'My Profile_Family_Cancel_Button',
    profileHabitsButton: 'My Profile_Habits_Habits_Button',
    profileHabitsSaveButton: 'My Profile_Habits_Save_Button',
    profileHabitsCancelButton: 'My Profile_Habits_Cancel_Button',
    profilePartnerPrefernceButton: 'My Profile_Partner Preferences_Partner Preferences_Button',
    profilePartnerPrefernceSaveButton: 'My Profile_Partner Preferences_Save Changes_Button',
    profilePartnerPrefernceSaveSearchButton: 'My Profile_Partner Preferences_Save Changes & Search_Button',
    profilePartnerPrefernceCancelButton: 'My Profile_Partner Preferences_Cancel_Button',
    profilePhotoAddButton: 'My Profile_Photos_Add icon_Button',
    profilePhotoAddMoreButton: 'My Profile_Photos_Add More_Button',
    profilePhotoThreeDotButton: 'My Profile_Photos_Three Dots_Button',
    profileSetAsProfileButton: 'My Profile_Photos  Three Dots_Set as Profile_Button',
    profileDeleteButton: 'My Profile_Photos  Three Dots_Delete_Button',
    profileAddIdProofButton: 'My Profile_Trust Mark_Add ID Proof_Button',
    profileAddEducationProofButton: 'My Profile_Trust Mark_Add Education Proof_Button',
    profileAddIncomeProofButton: 'My Profile_Trust Mark_Add Income Proof_Button',
    profileGenerateHoroscope: 'My Profile_Horoscope_Generate Horoscope_Button',
    profileUploadHoroscope: 'My Profile_Horoscope_Upload Horoscope_Button',
    profileSettingsEmailAlertButton: 'My Profile_Settings_Email Alerts_Button',
    profileSettingsSaveButton: 'My Profile_Settings_Save_Button',
    profilePhoneNumberEditButton: 'My Profile_Settings_Phone Number  Edit_Button',
    profilePhoneNumberSaveButton: 'My Profile_Settings_Phone Number  Save_Button',
    profilePhoneNumberCancelButton: 'My Profile_Settings_Phone Number  Cancel_Button',
    profileVerifyMobileButton: 'My Profile_Settings_Mobile Verification Submit_Button',
    profileRequestAgainButton: 'My Profile_Settings_Mobile Verification Request Again_Button',
    profileProfileStatusEditButton: 'My Profile_Settings_Profile Status  Edit_Button',
    profileProfileStatusSaveButton: 'My Profile_Settings_Profile Status  Save_Button',
    profileProfileStatusCancelButton: 'My Profile_Settings_Profile Status  Cancel_Button',
    profileEmailIdEditButton: 'My Profile_Settings_Email Id  Edit_Button',
    profileEmailIdSaveButton: 'My Profile_Settings_Email Id  Save_Button',
    profileEmailIdCancelButton: 'My Profile_Settings_Email Id  Cancel_Button',
    profilePasswordEditButton: 'My Profile_Settings_Password  Edit_Button',
    profilePasswordSaveButton: 'My Profile_Settings_Password  Save_Button',
    profilePasswordCancelButton: 'My Profile_Settings_Password  Cancel_Button',
    profilePrivacySettingPlusButton: 'My Profile_Settings_Privacy Settings_Button',
    profilePrivacySettingsSaveButton: 'My Profile_Settings_Privacy Settings  Save_Button',
    profileMyPlanButton: 'My Profile_My Plan_My Plan_Button',
    profileViewByMeButton: 'My Profile_Profiles Viewed By Me_Profiles Viewed By Me_Button',
    profileWhoViewedMyProfileButton: 'My Profile_Who Viewed My Profile_Who Viewed My Profile_Button',
    profileWhoViewesMyProfileIdButton: 'My Profile_Who Viewed My Profile_Who Viewed My Profile  Profile ID_Button',
    profilePhoneNumbersViewedByMeButton: 'My Profile_Phone Numbers Viewed By Me_Phone Numbers Viewed By Me_Button',
    profileWhoViewedMyPhoneNumberButton: 'My Profile_Who Viewed My Phone Number_Who Viewed My Phone Number_Button',
    profileWhoViewedMyPhoneNumberProfileIdButton: 'My Profile_Who Viewed My Phone Number_Who Viewed My Phone Number   Profile ID_Button',
    profileSettingsDeleteMarriageFixedButton: 'My Profile_Settings Delete_Marriage Fixed_Button',
    profileSettingsDeleteMarriageFixedSubmitButton: 'My Profile_Settings Delete_Marriage Fixed Submit_Button',
    profileSettingsDeleteMarriagedButton: 'My Profile_Settings Delete_Married_Button',
    profileSettingsDeleteMarriagedSubmitButton: 'My Profile_Settings Delete_Married Submit_Button',
    profileSettingsDeletePreferToSearchLaterButton: 'My Profile_Settings Delete_Prefer to Search Later_Button',
    profileSettingsDeletePreferToSearchLaterSubmitButton: 'My Profile_Settings Delete_Prefer to Search Later Submit_Button',
    profileSettingsDeleteNotHappyWithWebsiteButton: 'My Profile_Settings Delete_Not Happy With Website_Button',
    profileSettingsDeleteNotHappyWithWebsiteSubmitButton: 'My Profile_Settings Delete_Not Happy With Website Submit_Button',
    profileSettingsDeleteOtherReasonButton: 'My Profile_Settings Delete_Other Reasons_Button',
    profileSettingsDeleteOtherReasonSubmitButton: 'My Profile_Settings Delete_Other Reasons Submit_Button',
    // filterapplyButton:'Filter_Main Filter_Apply_Button',
    // filterclearallButton:'Filter_Main Filter_Clear All_Button',
    // filtercancelButton:'Filter_Main Filter_Cancel_Button',
    // filtereditfilterButton:'Filter_Main Filter Results Page_Edit Filters_Button',
    // filtereditfilterhereButton:'Filter_Main Filter Results Page_Edit Filters here_Button',
    // filterexploredifferentprofileButton:'Filter_Main Filter_Explore Different Profiles_Button',
    // filterbasicsearchButton:'Filter_Basic Search_Basic Search_Button',
    // filterbasiceditfilterButton:'Filter_Basic Search Results Page_Edit Filters here_Button',
    // filteradvancesearchButton:'Filter_Advnaced Search_Advanced Search_Field',

    //payment page 
    paymentSelectLimitedPack: 'Payment_Limited Pack_Select_Button',
    paymentSelectUnLimitedPack: 'Payment_Unlimited Validity Pack_Select_Button',
    paymentSelectUnLimitedPhonePack: 'Payment_Unlimited Phone Pack_Select_Button',
    paymentSelectTrulyUnLimitedPack: 'Payment_Truly Unlimited Pack_Select_Button',
    paymentSelectKalyanPremierPack: 'Payment_Kalyan Premier_Select_Button',
    paymentSelectAstroMatch: 'Payment_Astro Match_Select_Button',
    paymentDiscountCodeApplyButton: 'Payment_Discount Code_Apply_Button',
    SelectPaynowButton: 'Payment_Pay Now_Pay Now_Button',
    //chat
    Chat_Chat_Chat_Button: 'Chat_Chat_Chat_Button',
    chatProfileCardClick: 'Chat_Chat_Profile Card_Button',
    chatSearchButtonClick: 'Chat_Chat_Search_Field',
    chatNewConversationButtonClick: 'Chat_Chat_New Conversation_Button',
    chatSearchAlreadyContactedButtonClick: 'Chat_Search_Already Contacted_Button',
    chatSearchSelectedProfileButtonClick: 'Chat_Search_Selected Profiles_Button',
    chatSearchProfileCardClick: 'Chat_Search_Profile Card_Button',
    chatNewConversationAlreadyContactedButtonClick: 'Chat_New Conversation_Already Contacted_Button',
    chatNewConversationSelectedProfilesButtonClick: 'Chat_New Conversation_Selected Profiles_Button',
    chatNewConversationProfileCardClick: 'Chat_New Conversation_Profile Card_Button',
    chatCustomerCareButtonClick: 'Chat_Chat_Customer Care_Button',
    chatProfilePicClick: 'Chat_Conversation Page_Profile Photo_Button',
    chatProfileNameClick: 'Chat_Conversation Page_Profile Name_Button',
    chatVideoCallClick: 'Chat_Conversation Page_Video Call_Button',
    chatParentsNumClick: `Chat_Conversation Page_Parent's Number_Button`,
    chatPayNowButtonClick: 'Chat_Conversation Page  All Pay Now Popup_Pay Now_Button',
    chatProfileNumClick: 'Chat_Conversation Page_Profile Number_Button',
    chatThreeDotProfileClick: 'Chat_Conversation Page  Three Dots_View Profile_Button',
    chatThreeDotProfileBackClick: 'Chat_Conversation Page  Three Dots_View Profile  Back_Button',
    chatThreeDotMediaLinksClick: 'Chat_Conversation Page  Three Dots_Media,Links and Docs_Button',
    chatThreeDotSearchClick: 'Chat_Conversation Page  Three Dots_Search_Button',
    chatSearchInputClick: 'Chat_Conversation Page  Three Dots_Search Input_Field',
    chatThreeDotSearchBackClick: 'Chat_Conversation Page  Three Dots_Search  Back_Button',
    chatThreeDotMuteClick: 'Chat_Conversation Page  Three Dots_Mute Notifications_Button',
    chatThreeDotMuteOKClick: 'Chat_Conversation Page  Three Dots_Mute Notifications  Ok_Button',
    chatThreeDotMuteCancelClick: 'Chat_Conversation Page  Three Dots_Mute Notifications  Cancel_Button',
    chatThreeDotStarredMsgClick: 'Chat_Conversation Page  Three Dots_Starred Messages_Button',
    chatThreeDotStarredMsgBackClick: 'Chat_Conversation Page  Three Dots_Starred Messages  Back_Button',
    chatThreeDotMoreClick: 'Chat_Conversation Page  Three Dots_More_Button',
    chatThreeDotHoroscopeClick: 'Chat_Conversation Page  Three DotsMore_View Horoscope_Button',
    chatThreeDotTrustedDocumentClick: 'Chat_Conversation Page  Three DotsMore_View Trust Documents_Button',
    chatThreeDotBlockClick: 'Chat_Conversation Page  Three DotsMore_Block_Button',
    chatThreeDotBlockButtonClick: 'Chat_Conversation Page  Three DotsMore_Block  Block_Button',
    chatThreeDotBlockCancelClick: 'Chat_Conversation Page  Three DotsMore_Block  Cancel_Button',
    chatThreeDotBlockReportContactRadioClick: 'Chat_Conversation Page  Three DotsMore_Report Contact_Button',
    chatThreeDotBlockReport_BlockButtonClick: 'Chat_Conversation Page  Three DotsMore_Report & Block_Button',
    chatThreeDotReportClick: 'Chat_Conversation Page  Three DotsMore_Report_Button',
    chatThreeDotReportButtonClick: 'Chat_Conversation Page  Three DotsMore_Report  Report_Button',
    chatThreeDotReportCancelClick: 'Chat_Conversation Page  Three DotsMore_Report  Cancel_Button',
    chatThreeDotReportBlockContactRadioClick: 'Chat_Conversation Page  Three DotsMore_Block Contact_Button',
    chatThreeDotReport_ReportAndBlockButtonClick: 'Chat_Conversation Page  Three DotsMore_Report & Block_Button',
    chatRequestPhotoButton: 'Chat_Conversation Page  Request_Request Photo_Button',
    chatRequestParentsPhoneNumberButton: `Chat_Conversation Page  Request_Request Parent's Phone Number_Button`,
    chatRequestHoroscopeButton: 'Chat_Conversation Page  Request_Request Horoscope_Button',
    chatRequestTrustDocumentsButton: 'Chat_Conversation Page  Request_Request Trust Document_Button',
    chatReportAnAbuseButton: 'Chat_Conversation Page  Message Bar_Report an abuse_Button',
    chatEmojiButtonClick: 'Chat_Conversation Page  Message Bar_Emoji_Button',
    chatMessageBarText: 'Chat_Conversation Page  Message Bar_Message_Field',
    chatAttachmentButtonClick: 'Chat_Conversation Page  Message Bar_Attachments_Button',
    chatAttachmentButtonDocumentClick: 'Chat_Conversation Page  Message Bar_Attachments  Documents_Button',
    chatAttachmentButtonPhotoClick: 'Chat_Conversation Page  Message Bar_Attachments  Photos_Button',
    chatCameraButtonClick: 'Chat_Conversation Page  Message Bar_Camera_Button',
    chatCameraButtonCancelClick: 'Chat_Conversation Page  Message Bar_Camera  Cancel_Button',
    chatCameraButtonCaptureClick: 'Chat_Conversation Page  Message Bar_Camera  Capture_Button',
    chatPaymentButtonClick: 'Chat_Conversation Page  Message Bar_Payment Button_Button',
    chatMessageSendButtonClick: 'Chat_Conversation Page  Message Bar_Send Button_Button',
    chatVoiceMessageSendButtonClick: 'Chat_Conversation Page  Message Bar_Voice Message_Button',
    chatVoiceMessageSendPauseButtonClick: 'Chat_Conversation Page  Message Bar_Voice Message  Pause_Button',
    chatVoiceMessageSendDeleteButtonClick: 'Chat_Conversation Page  Message Bar_Voice Message  Delete_Button',
    chatStarButtonClick: 'Chat_Conversation Page_Star_Button',
    chatDeleteButtonClick: 'Chat_Conversation Page_Delete_Button',
    chatReplyButtonClick: 'Chat_Conversation Page_Reply_Button',
    // Profile Registration
    profileRegisterSubmitButton: 'Profile Registration_Register_Submit & Continue_Button',
    // Mobile verification otp
    MobileVerify_VerifyButtonClick: 'Mobile Verification_Register_Verify Mobile_Button',
    //After Registeration
    AfterRegistration_Photo_Upload_Photos_Button: 'After Registration_Photo_Upload Photos_Button',
    AfterRegistrationSubmitPartnerPreferenceButton: 'After Registration_Set Partner Expectations_Submit & Continue_Button',
    AfterRegistrationSkipPartnerPreferenceButton: 'After Registration_Set Partner Expectations_Skip_Button',
    AfterRegistrationParentsNumberButton: 'After Registration_Parents Number_Submit & Continue_Button',
    AfterRegistrationParentsNumberSkipButton: 'After Registration_Parents Number_Skip_Button',
    AfterRegistrationViewMatchesButton: 'After Registration_View Matches_View Matches_Button',
    // AfterRegistrationDownloadAppStoreButton: 'After Registration_Download Our App_App Store_Button',
    // AfterRegistrationDownloadPlayStoreButton: 'After Registration_Download Our App_Play Store_Button',
    AfterRegistrationDownloadAppButton: 'After Registration_Download Mobile App Button_Site',
    AfterRegistrationDownloadAppStoreButton: 'After Registration_Download on the App Store Button_Site',
    AfterRegistrationDownloadPlayStoreButton: 'After Registration_Get it on Google Play Button_Site',
    AfterRegistrationDownloadOurAppSkipButton: 'After Registration_Download Our App_Skip_Button',
    AfterRegistrationUploadHoroscopeButton: 'After Registration_Horoscope_Upload Horoscope_Button',
    AfterRegistrationGenerateHoroscopeButton: 'After Registration_Horoscope_Generate Horoscope_Button',
    AfterRegistrationHoroscopeSkipButton: 'After Registration_Horoscope_Skip_Button',

    //notification

    Notifications_Notifications_Notifications_Button: "Notifications_Notifications_Notifications_Button",
    Notifications_Notifications_See_More_Button: "Notifications_Notifications_See More_Button",
    //Three dots
    Three_Dots_Profile_Photo_Profile_Photo_Button: "Three Dots_Profile Photo_Profile Photo_Button",
    Three_Dots_Profile_Name_Profile_Name_Button: "Three Dots_Profile Name_Profile Name_Button",
    Three_Dots_Buy_a_Pack_Buy_a_Pack_Button: "Three Dots_Buy a Pack_Buy a Pack_Button",
    Three_Dots_My_Plan_My_Plan_Button: "Three Dots_My Plan_My Plan_Button",
    Three_Dots_Set_My_Expectations_Set_My_Expectations_Button: "Three Dots_Set My Expectations_Set My Expectations_Button",
    Three_Dots_Selected_Profiles_Selected_Profiles_Button: "Three Dots_Selected Profiles_Selected Profiles_Button",
    Three_Dots_Hep_Help_Button: "Three Dots_Hep_Help_Button",
    Three_Dots_Settings_Settings_Button: "Three Dots_Settings_Settings_Button",
    Three_Dots_Logout_Logout_Button: "Three Dots_Logout_Logout_Button",
    Family_Add_Friends_Family_Add_Friends_Family_Button: "Family_Add Friends & Family_Add Friends & Family_Button",
    Family_Parent_Number_Popup_Submit_Button: "Family_Parent Number Popup_Submit_Button",
    Family_Add_More_Add_More_Button: "Family_Add More_Add More_Button",
    Family_Add_More_Send_Invite_Button: "Family_Add More_Send Invite_Button",
    Home_page_About_Kalyan_Matrimony: "Home page_About Kalyan Matrimony",
    Home_page_Safety_Security: "Home page_Safety & Security",
    Home_page_How_it_Works: "Home page_How it Works",
    Home_page_About_Kalyan_Jewellers: "Home page_About Kalyan Jewellers",
    Home_page_Help_Support: "Home page_Help & Support",
    Home_page_FAQs: "Home page_FAQs",
    Home_page_Contact_Us: "Home page_Contact Us",
    Home_page_Feedback: "Home page_Feedback",
    Home_page_About_us: "Home page_About us",
    Home_page_Terms_Conditions: "Home page_Terms & Conditions",
    Home_page_Kalyan_Gold_Scheme: "Home page_Kalyan Gold Scheme",
    Home_page_Privacy_Policy: "Home page_Privacy Policy",
    Home_page_Delete_Photo: "Home_page_Delete_Photo",
    Home_page_Book_an_Appointment: "Home page_Book an Appointment",
    Home_page_Download_App_Play_Store: "Home page_Download App - Play Store",
    Home_page_Download_App_AppStore: "Home page_Download App - App Store",
    Home_page_Buy_Gold_Saving_Scheme_Online: "Home page_Buy Gold Saving Scheme Online",
    Home_page_Register_Register_Button: "Home page_Register_Register_Button",
    Home_page_Register_Submit_Button: "Home page_Register_Submit & Continue_Button",
    HomePageHelpButtonClick: 'Home page_Help_Help_Button',
    HomePageSigninField: 'Home page_Sign in_Sign in_Field',
    HomePageLoginButtonClick: 'Home page_Login_Login_Button',
    Home_page_Signin_Signin_Field: "Home page_Sign in_Sign in_Field",
    // Home_page_Register_Submit_Button:"Home page_Register_Submit & Continue_Button",
    Home_page_Sign_in_Remember_me_Button: "Home page_Sign in_Remember me_Button",
    Home_page_Signin_Login_as_Family_Friend_Button: "Home page_Sign in_Login as Family/Friend_Button",
    Home_page_Signin_Forgot_Password_Button: "Home page_Sign in_Forgot Password_Button",
    Home_page_Forgot_Password_Forgot_Password_Button: "Home page_Forgot Password_Forgot Password_Button",
    Home_page_Forgot_Password_Submit_Button: "Home page_Forgot Password_Submit_Button",
    Home_page_Forgot_Password_Resend_OTP_Button: "Home page_Forgot Password_Resend OTP_Button",
    Home_page_Login_as_Family_Friend_Cancel_Button: "Home page_Login as Family/Friend_Cancel_Button",
    Home_page_Login_as_FamilyFriend_Submit_Button: "Home page_Login as Family/Friend_Submit_Button",
    Home_page_Signin_Submit_Button: "Home page_Sign in_Submit_Button",
    Home_page_Login_as_FamilyFriend_Login_Button: 'Home page_Login as Family/Friend_Login_Button',
    Home_page_Login_as_FamilyFriend_Cancel_Button: 'Home page_Login as Family/Friend_Cancel_Button',


    //banner click events
    V3_Matches_Family_banner_Add_Family_Members_Button: "V3_Matches_Family_banner_01_Add Family Members_Button",
    V3_Matches_KJ_Banner_Book_Appointment_Button: "V3_Matches_KJ_Banner_Book Appointment_Button",
    V3_Matches_Add_Photo_Banner_Add_Photo_Now_Button: "V3_Matches_Add_Photo_Banner_Add Photo Now_Button",
    V3_Matches_Top_KJ_Banner_Book_Appointment_Button: "V3_Matches_Top_KJ_Banner_Book Appointment_Button",

    //content card click
    V3_Matches_Family_Content_Card_Add_Family_Members_Button: "Matches_Family Content Card_Add Family Members_Button",
    V3_Matches_Add_Photo_Content_Card_Add_Now_Button: "Matches_Add Photo Content Card_Add Now_Button",
    V3_Matches_Add_Parents_Number_Content_Card_Add_Now_Button: "Matches_Add Parents Number Content Card_Add Now_Button",
    V3_Matches_Add_Partner_Expectation_Content_Card_Set_Now_Button: "Matches_Add Partner Expectation Content Card_Set Now_Button",
    V3_Matches_Download_App_Content_Card_Download_Now_Button: "Matches_Download App Content Card_Download Now_Button",

    //download popup
    Home_CLC_Redirect_Continue_with_Mobile_site_Button: "Home_CLC_Redirect_Continue_with_Mobile_site_Button",
    Home_CLC_Redirect_Open_with_Android_app_Button: "Home_CLC_Redirect_Open_with_Android_app_Button",


    KMcare_Chat_Add_family_template_Share_app_link_Button: "KMcare_Chat_Add_family_template_Share_app_link_Button",
    KMcare_Chat_Add_family_template_Add_member_Button: "KMcare_Chat_Add_family_template_Add_member_Button",
    KMcare_Chat_Add_family_template_family_banner_image_Button: "KMcare_Chat_Add_family_template_family_banner_image_Button",



    ViewProfilePromptPopupRequestButton: "View Profile_Prompt_popup_Request_Button",
    ViewProfilePromptPopupCancelButton: "V3_View Profile_Prompt_popup_Cancel_Button",

    web_addphotopopup_callexecutive: "web_addphotopopup_callexecutive",

    // view profile request button
    View_Profile_Not_Specified_field_request_SubCaste_Button: "View Profile_Not Specified field request_Sub-Caste_Button",
    View_Profile_Not_Specified_field_request_MonthlyIncome_Button: "View Profile_Not Specified field request_MonthlyIncome_Button",
    View_Profile_Not_Specified_field_request_Star_Button: "View Profile_Not Specified field request_Star_Button",
    View_Profile_Not_Specified_field_request_Raasi_Button: "View Profile_Not Specified field request_Raasi_Button",
    View_Profile_Not_Specified_field_request_ChevvaiDosham_Mangalik_Button: "View Profile_Not Specified field request_ChevvaiDosham/Mangalik_Button",
    View_Profile_Not_Specified_field_request_Habit_details_Button: "View Profile_Not Specified field request_Habit details_Button",
    View_Profile_Not_Specified_field_request_FoodHabits_Button: "View Profile_Not Specified field request_FoodHabits_Button",
    View_Profile_Not_Specified_field_request_Smoking_Button: "View Profile_Not Specified field request_Smoking_Button",
    View_Profile_Not_Specified_field_request_Drinking_Button: "View Profile_Not Specified field request_Drinking_Button",
    View_Profile_Not_Specified_field_request_FamilyStatus_Button: "View Profile_Not Specified field request_FamilyStatus_Button",
    View_Profile_Not_Specified_field_request_FamilyType_Button: "View Profile_Not Specified field request_FamilyType_Button",
    View_Profile_Not_Specified_field_request_FamilyValues_Button: "View Profile_Not Specified field request_FamilyValues_Button",
    View_Profile_Not_Specified_field_request_ParentsMonthlyIncome_Button: "View Profile_Not Specified field request_ParentsMonthlyIncome_Button",
    View_Profile_Not_Specified_field_request_Description_box_Button: "View Profile_Not Specified field request_Description box_Button",
    View_Profile_Not_Specified_field_request_Hobbies_And_Interest_Button: "View Profile_Not Specified field request_Hobbies&Interest_Button",
    // additional fields
    View_Profile_Not_Specified_field_request_Fathers_Name_Button: "View Profile_Not Specified field request_Father’s Name_Button",
    View_Profile_Not_Specified_field_request_Fathers_Occupation_Button: "View Profile_Not Specified field request_Father’s Occupation_Button",
    View_Profile_Not_Specified_field_request_Mothers_Name_Button: "View Profile_Not Specified field request_Mother’s Name_Button",
    View_Profile_Not_Specified_field_request_Mothers_Occupation_Button: "View Profile_Not Specified field request_Mother’s Occupation_Button",
    View_Profile_Not_Specified_field_request_Brothers_Button: "View Profile_Not Specified field request_Brothers_Button",
    View_Profile_Not_Specified_field_request_Married_Brothers_Button: "View Profile_Not Specified field request_Married Brothers_Button",
    View_Profile_Not_Specified_field_request_Sisters_Button: "View Profile_Not Specified field request_Sisters_Button",
    View_Profile_Not_Specified_field_request_Married_Sisters_Button: "View Profile_Not Specified field request_Married Sisters_Button",
    View_Profile_Not_Specified_field_request_Assets_Owned_Button: "View Profile_Not Specified field request_Assets Owned _Button",

    // add button click on chatpage
    Chat_Not_Specified_field_request_fulfillment_Sub_Caste_Button: "Chat_Not Specified field request fulfillment_Sub-Caste_Button",
    Chat_Not_Specified_field_request_fulfillment_MonthlyIncome_Button: "Chat_Not Specified field request fulfillment_MonthlyIncome_Button",
    Chat_Not_Specified_field_request_fulfillment_Star_Button: "Chat_Not Specified field request fulfillment_Star_Button",
    Chat_Not_Specified_field_request_fulfillment_Raasi_Button: "Chat_Not Specified field request fulfillment_Raasi_Button",
    Chat_Not_Specified_field_request_fulfillment_ChevvaiDosham_Mangalik_Button: "Chat_Not Specified field request fulfillment_ChevvaiDosham/Mangalik_Button",
    Chat_Not_Specified_field_request_fulfillment_Habit_details_Button: "Chat_Not Specified field request fulfillment_Habit details_Button",
    Chat_Not_Specified_field_request_fulfillment_FoodHabits_Button: "Chat_Not Specified field request fulfillment_FoodHabits_Button",
    Chat_Not_Specified_field_request_fulfillment_Smoking_Button: "Chat_Not Specified field request fulfillment_Smoking_Button",
    Chat_Not_Specified_field_request_fulfillment_Drinking_Button: "Chat_Not Specified field request fulfillment_Drinking_Button",
    Chat_Not_Specified_field_request_fulfillment_FamilyStatus_Button: "Chat_Not Specified field request fulfillment_FamilyStatus_Button",
    Chat_Not_Specified_field_request_fulfillment_FamilyType_Button: "Chat_Not Specified field request fulfillment_FamilyType_Button",
    Chat_Not_Specified_field_request_fulfillment_FamilyValues_Button: "Chat_Not Specified field request fulfillment_FamilyValues_Button",
    Chat_Not_Specified_field_request_fulfillment_ParentsMonthlyIncome_Button: "Chat_Not Specified field request fulfillment_ParentsMonthlyIncome_Button",
    Chat_Not_Specified_field_request_fulfillment_Description_box_Button: "Chat_Not Specified field request fulfillment_Description box_Button",
    Chat_Not_Specified_field_request_fulfillment_Hobbies_And_Interest_Button: "Chat_Not Specified field request fulfillment_Hobbies&Interest_Button",
    Chat_Not_Specified_field_request_fulfillment_Fathers_Name_Button: "Chat_Not Specified field request fulfillment_Fathers Name_Button",
    Chat_Not_Specified_field_request_fulfillment_Fathers_Occupation_Button: "Chat_Not Specified field request fulfillment_Fathers Occupation_Button",
    Chat_Not_Specified_field_request_fulfillment_Mothers_Name_Button: "Chat_Not Specified field request fulfillment_Mothers Name_Button",
    Chat_Not_Specified_field_request_fulfillment_Mothers_Occupation_Button: "Chat_Not Specified field request fulfillment_Mother’s Occupation_Button",
    Chat_Not_Specified_field_request_fulfillment_Brothers_Button: "Chat_Not Specified field request fulfillment_Brothers_Button",
    Chat_Not_Specified_field_request_fulfillment_Married_Brothers_Button: "Chat_Not Specified field request fulfillment_Married Brothers_Button",
    Chat_Not_Specified_field_request_fulfillment_Sisters_Button: "Chat_Not Specified field request fulfillment_Sisters_Button",
    Chat_Not_Specified_field_request_fulfillment_Married_Sisters_Button: "Chat_Not Specified field request fulfillment_Married Sisters_Button",
    Chat_Not_Specified_field_request_fulfillment_Assets_Owned_Button: "Chat_Not Specified field request fulfillment_Assets Owned_Button",


    //non mandatory form save button
    Chat_Request_fulfillment_popup_Save_Religious_details_Button: "Chat_Request fulfillment popup Save_Religious details_Button",
    Chat_Request_fulfillment_popup_Save_Professional_details_Button: "Chat_Request fulfillment popup Save_Professional details_Button",
    Chat_Request_fulfillment_popup_Save_Astro_details_Button: "Chat_Request fulfillment popup Save_Astro details_Button",
    Chat_Request_fulfillment_popup_Save_Family_details_Button: "Chat_Request fulfillment popup Save_Family details_Button",
    Chat_Request_fulfillment_popup_Save_Description_Button: "Chat_Request fulfillment popup Save_Description_Button",
    Chat_Request_fulfillment_popup_Save_Hobbies_And_Interest_Button: "Chat_Request fulfillment popup Save_Hobbies&Interest_Button",
    Chat_Request_fulfillment_popup_Save_Additional_Information_Button: "Chat_Request fulfillment popup Save_Additional Information_Button",

    //non mandatory form close or cancel button
    Chat_Request_fulfillment_popup_Cancel_Religious_details_Button: "Chat_Request fulfillment popup Cancel_Religious details_Button",
    Chat_Request_fulfillment_popup_Cancel_Professional_details_Button: "Chat_Request fulfillment popup Cancel_Professional details_Button",
    Chat_Request_fulfillment_popup_Cancel_Astro_details_Button: "Chat_Request fulfillment popup Cancel_Astro details_Button",
    Chat_Request_fulfillment_popup_Cancel_Family_details_Button: "Chat_Request fulfillment popup Cancel_Family details_Button",
    Chat_Request_fulfillment_popup_Cancel_Description_Button: "Chat_Request fulfillment popup Cancel_Description_Button",
    Chat_Request_fulfillment_popup_Cancel_Hobbies_And_Interest_Button: "Chat_Request fulfillment popup Cancel_Hobbies&Interest_Button",
    Chat_Request_fulfillment_popup_Cancel_Additional_Information_Button: "Chat_Request fulfillment popup Cancel_Additional Information_Button",

    // CLC
    CLC_Add_Photo_Now_Button_Add_Photo_View_Matches_Popup: "CLC_Add Photo Now Button Add Photo & View Matches Popup",
    CLC_View_Matches_Button_Add_Photo_View_Matches_Popup: "CLC_View Matches Button Add Photo & View Matches Popup",
    CLC_Close_Button_Add_Photo_View_Matches_Popup: "CLC_Close Button Add Photo & View Matches Popup",
    // privacy settings 
    CLC_Add_Now_Button_V3_Site_Add_Photo_with_Privacy_Options_Popup: "CLC_Add Now Button_V3_ Site_Add Photo with Privacy Options Popup",
    CLC_Close_Button_V3_Site_Add_Photo_with_Privacy_Options_Popup: "CLC_Close Button_V3_ Site_Add Photo with Privacy Options Popup",


    //clc star raasi on click 

    CLCAddNowButton: "CLC_Add Now Button_V3_ Site_Star & Rassi Popup",
    CLC_CloseButton: "CLC_Close Button_V3_ Site_Star & Rassi Popup",
    CLC_AstroCloseButton: "CLC_Close Button_V3_ Site_Astrological Information Popup",
    CLC_AstroSaveButton: "CLC_Save Button_V3_ Site_Astrological Information Popup",
    CLCSaveButton: "CLC_Save Button_V3_ Site_Astrological Information Popup",

    // privacy settings 
    CLC_Add_Now_Button_V3_Site_Add_Photo_with_Privacy_Options_Popup: "CLC_Add Now Button_V3_ Site_Add Photo with Privacy Options Popup",
    CLC_Close_Button_V3_Site_Add_Photo_with_Privacy_Options_Popup: "CLC_Close Button_V3_ Site_Add Photo with Privacy Options Popup",

    // clc horoscope
    CLC_Add_Horoscope_Now_Button_V3_Site_Generate_Upload_Horoscope_Popup: "CLC_Add Horoscope Now Button_V3_ Site_Generate/Upload Horoscope Popup",
    CLC_Close_Button_V3_Generate_Upload_Horoscope_Popup: "CLC_Close Button_V3_ Generate/Upload Horoscope Popup",
    CLC_Generate_Button_V3_Site_Generate_Upload_Horoscope_Popup: "CLC_Generate Button_V3_ Site_Generate/Upload Horoscope Popup",
    CLC_Upload_Button_V3_Site_Generate_Upload_Horoscope_Popup: "CLC_Upload Button_V3_ Site_Generate/Upload Horoscope Popup",
    CLC_Close_Button_V3_Site_Generate_Upload_Horoscope_Popup: "CLC_Close Button_V3_ Site_Generate/Upload Horoscope Popup",

    // TrailPack 
    Trial_Pack_popup_Add_Your_Photo_Add_Now_Button :  "Trial Pack popup_Add Your Photo_Add Now_Button",
    Trial_Pack_popup_Add_Your_Photo_Upload_Button : "Trial Pack popup_Add Your Photo_Upload_Button",
    Trial_Pack_popup_Add_Your_Family_Details_Add_Now_Button : "Trial Pack popup_Add Your Family Details_Add Now_Button",
    Trial_Pack_popup_Add_Your_Family_Details_Submit_Button : "Trial Pack popup_Add Your Family Details_Submit_Button",
    Trial_Pack_popup_Add_Horoscope_Add_Now_Button : "Trial Pack popup_Add Horoscope_Add Now_Button",
    Trial_Pack_popup_Add_Horoscope_Upload_Button : "Trial Pack popup_Add Horoscope_Upload_Button",
    Trial_Pack_popup_Add_Horoscope_Generate_Button : "Trial Pack popup_Add Horoscope_Generate_Button",
    Trial_Pack_popup_Set_Your_Expectations_Add_Now_Button : "Trial Pack popup_Set Your Expectations_Add Now_Button",
    Trial_Pack_popup_Set_Your_Expectations_Submit_Button : "Trial Pack popup_Set Your Expectations_Submit_Button",
    Trial_Pack_popup_Add_Astrological_Information_Add_Now_Button : "Trial Pack popup_Add Astrological Information_Add Now_Button",
    Trial_Pack_popup_Add_Astrological_Information_Submit_Button : "Trial Pack popup_Add Astrological Information_Submit_Button",
    Trial_Pack_popup_Add_Habit_Details_Add_Now_Button : "Trial Pack popup_Add Habit Details_Add Now_Button",
    Trial_Pack_popup_Add_Habit_Details_Submit_Button : "Trial Pack popup_Add Habit Details_Submit_Button",
    Trial_Pack_popup_Add_Monthly_Income_Add_Now_Button : "Trial Pack popup_Add Monthly Income_Add Now_Button",
    Trial_Pack_popup_Add_Monthly_Income_Submit_Button : "Trial Pack popup_Add Monthly Income_Submit_Button",
    Trial_Pack_popup_Add_HobbiesInterests_Add_Now_Button : "Trial Pack popup_Add Hobbies & Interests_Add Now_Button",
    Trial_Pack_popup_Add_HobbiesInterests_Submit_Button : "Trial Pack popup_Add Hobbies & Interests_Submit_Button",
    Trial_Pack_popup_Add_an_ID_Proof_Add_Now_Button : "Trial Pack popup_Add an ID Proof_Add Now_Button",
    Trial_Pack_popup_Add_an_ID_Proof_Upload_Button : "Trial Pack popup_Add an ID Proof_Upload_Button",
    Trial_Pack_popup_Add_Description_Add_Now_Button : "Trial Pack popup_Add Description_Add Now_Button",
    Trial_Pack_popup_Add_Description_Submit_Button : "Trial Pack popup_Add Description_Submit_Button",
    Trial_Pack_popup_Profile_Completion_Completed_Now_Button : "Trial Pack popup_Profile Completion_Completed Now_Button",
    Trial_Pack_popup_Claim_Trial_Pack_Claim_Now_Button : "Trial Pack popup_Claim Trial Pack_Claim Now_Button",
    Trial_Pack_popup_Add_Your_Photo_Camera_Button : "Trial Pack popup_Add Your Photo_Camera_Button",
    Trial_Pack_popup_Add_Your_Photo_Whatsapp_Button : "Trial Pack popup_Add Your Photo_Whatsapp_Button",
    Trial_Pack_popup_Add_Your_Photo_Facebook_Button : "Trial Pack popup_Add Your Photo_Facebook_Button",
    Trial_Pack_popup_Add_Your_Photo_Instagram_Button : "Trial Pack popup_Add Your Photo_Instagram_Button",

}

export const PageName = {
    Home_page_Home_Page: 'Home page_Home Page',
    Home_page_Help_Support: "Home page_Help & Support",
    Home_page_FAQs: "Home page_FAQs",
    Home_page_Contact_Us: "Home page_Contact Us",
    Home_page_Feedback: "Home page_Feedback",
    Home_page_About_us: "Home page_About us",
    Home_page_Safety_Security: "Home page_Safety & Security",
    Home_page_How_it_Works: "Home page_How it Works",
    Home_page_About_Kalyan_Jewellers: "Home page_About Kalyan Jewellers",
    Home_page_Terms_Conditions: "Home page_Terms & Conditions",
    Home_page_Book_an_Appointment: "Home page_Book an Appointment",
    Home_page_Kalyan_Gold_Scheme: "Home page_Kalyan Gold Scheme",
    Home_page_Privacy_Policy: "Home page_Privacy Policy",
    Home_page_Delete_Photo: "Home_page_Delete_Photo",
    Matches_Matches: "Matches_Matches",
    Matches_New_conversation: "Matches_New conversation",
    Family_Family: "Family_Family",
    Filter_Main_Filter: "Filter_Filter - Main Filter",
    Chat_Chat: "Chat_Chat",
    Chat_Chat_Search: "Chat_Chat - Search",
    Payment_Payment: "Payment_Payment",

    // My Profile
    MyProfileButton: "My Profile_My profile",
    ProfileButton: "My Profile_My Profile - My Profile",
    ProfilePartnerPreference: "My Profile_My Profile - Partner Preference",
    ProfilePhotos: "My Profile_My Profile - Photos",
    ProfileTrustMark: "My Profile_My Profile - Trust Mark",
    ProfileHoroscope: "My Profile_My Profile - Horoscope",
    ProfileSettings: "My Profile_My Profile - Settings",
    ProfileMyPlan: "My Profile_My Profile - My Plan",
    ProfileViewedByMe: "My Profile_My Profile - Profiles Viewed By Me",
    ProfileWhoViewedMyProfile: "My Profile_My Profile - Who Viewed By Profile",
    ProfilePhoneNumberViewedByMe: "My Profile_My Profile - Phone Numbers Viewed By Me",
    ProfileWhoViewedMyPhoneNumber: "My Profile_My Profile - Who Viewed My Phone Number",
    WeeklyMatches: "Filter_Main Filter Result -  Weekly Matches",

    Notifications_Notifications: "Notifications_Notifications"
}


export const commonPopupButtonNames = {
    NewMatches: "View Matches",
    PartnerPreference: "Set Now",
    Matches: "View Matches",
    StartMessage: "View Matches",
    AddPhoto: "Add Photo",
    AddParentsNumber: "Add Parents Number",
    ReadAndReply: "Read & Reply",
    UnreadMessage: "Read & Reply",
    MsgSeenNotReply: "Reply Now",
    DownloadApp: "Download Now",
    WhoViewedMyprofile: "WhoViewedMyprofile",
    PrivacyOption: "Add Photo Now!",
    Horoscope: "Add Horoscope Now!",
    StarRaasi: "Add Now!"
}

export const commonPopupTitleNames = {
    NewMatches: "New Matches",
    PartnerPreference: "Set Your Partner Expectations",
    Matches: "Matches",
    StartMessage: "Start Message",
    AddPhoto: "Add Photo",
    AddParentsNumber: "Add Parents Number",
    ReadAndReply: "New Message",
    UnreadMessage: "Unread Message",
    MsgSeenNotReply: "Reply Pending",
    DownloadApp: "Download our APP to get instant Matches",
    WhoViewedMyprofile: "Add Photo & View Matches",
    PrivacyOption: "Add Photo with Privacy Options",
    Horoscope: "Generate/ Upload Horoscope",
    PrivacyOption: "Add Photo with Privacy Options",
    StarRaasi: "Add Star & Rassi"
}

export const assetOptions = [
    { value: 1, label: 'House' },
    { value: 2, label: 'Flat' },
    { value: 3, label: 'Plot' },
    { value: 4, label: 'Land' },
    { value: 5, label: 'Car' },
    { value: 6, label: 'Bike' },
];

export const countOptions = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
];